import { createApp } from 'vue'
import App from './pages/App.vue'
import store from './vuex/app'
import router from './router/app'

const app = createApp(App);

app.use(store);
app.use(router);
app.mount('#app');
