export default {
    computed: {
        permissions () {
            return window.permissions;
        }
    },
    methods: {
        getFullName (signable) {
            return signable.firstname + ' ' + signable.lastname;
        },
        hasCompletedAdmissions (patient) {
            if (_.isEmpty(patient.admissions)) {
                return false;
            }
            return _.filter(patient.admissions, this.isAdmissionComplete).length !== 0;
        },
        isAdmissionComplete (admission) {
            return _.filter(admission.documents, this.isDocumentCompleted).length !== 0;
        },
        isDocumentActive (document) {
            return _.isNull(document.deleted_at);
        },
        isDocumentCompleted (document) {
            if (!_.isNull(document.bypassed_at)) {
                return true;
            }
            if (_.isEmpty(document.signatures)) {
                return false;
            }
            return _.filter(document.signatures, this.isSignatureComplete).length === document.signatures.length;
        },
        isDocumentIncomplete (document) {
            if (_.isNull(document.signature_request_id)) {
                return true;
            }
            if (!_.isNull(document.bypassed_at)) {
                return false;
            }
            return _.filter(document.signatures, this.isSignatureIncomplete).length !== 0;
        },
        isRemovable (signature) {
            if (signature.role === 'Patient') {
                return false;
            }
            return signature.status === 'Ready';
        },
        isSignatureComplete (signature) {
            if (!_.isUndefined(signature)) {
                return !_.isNull(signature.signature_id) && !_.isNull(signature.completed_at);
            }
        },
        isSignatureIncomplete (signature) {
            if (!_.isUndefined(signature)) {
                return !_.isNull(signature.signature_id) && _.isNull(signature.completed_at);
            }
        },
        isSignaturePending (signature) {
            if (!_.isUndefined(signature)) {
                return _.isNull(signature.signature_id) && _.isNull(signature.completed_at);
            }
        },
        packetHasSignerOrder (packet) {
            return !_.isNull(packet.signer_order);
        },
        isNextSigner (nextSigner, id, role) {
            return nextSigner.role === role && nextSigner.signable_id === id;
        },
        getPatientSignatureFromDocument (document, patient) {
            return _.find(document.signatures, function (signature) {
                return signature.role === 'Patient' && signature.signable_id === patient.id;
            });
        }
    },
}
