<script setup lang="ts">
import { useRoute } from 'vue-router'
import FollowupModal from './components/FollowupModal.vue'
import { computed, onMounted } from 'vue'
import { Modal } from 'bootstrap';

const route = useRoute()
const signatureId = computed(() => route.params.signatureId as string)

const followupModal = computed(() => {
  return Modal.getOrCreateInstance('#followup-modal', { backdrop: 'static' })
})

onMounted(() => {
  followupModal.value.show()
})
</script>

<template>
  <FollowupModal :signature-id="signatureId" @dismiss="followupModal.hide()" />
</template>