<template>
    <div class="main-content-wrap">
        <h5>Other Patients</h5>
        <div class="card">
            <div class="card-body">
                <template v-if="hasPatients">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col" class="">Patient Name</th>
                            <th scope="col" class="text-center">Current Facility</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="patient in signerPatients">
                            <tr>
                                <td>{{ patient.firstname }} {{ patient.lastname }}</td>
                                <td class="text-center">{{ patient.current_facility.name }}</td>
                                <td class="text-end">
                                    <button class="btn btn-primary" @click="switchPatient(patient)">
                                        <i class="fas fa-sync-alt"></i>
                                        Switch
                                    </button>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </template>
                <template>
                    No Patients Found
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthenticatedClient } from '@/mixins/app/useAuthenticatedClient'

export default {
    data () {
        return {
            signerPatients: null
        }
    },
    computed: {
        hasPatients () {
            return !_.isEmpty(this.signerPatients);
        },
        patient () {
            return this.$store.getters['store/patient'];
        },
        signer () {
            return this.$store.getters['store/signer'];
        },
    },
    methods: {
        getPatients: function () {
            let self = this;
            const { client } = useAuthenticatedClient(this.$store)
            client.get('/api/v1/signers/' + this.signer.id + '/patients').then(response => {
                self.signerPatients = response.data.patients;
            }).catch(error => {
                // Do Nothing
            });
        },
        switchPatient: function (patient) {
            this.$store.dispatch('store/setPatient', patient);
            this.$router.push('/dashboard');
        }
    },
    mounted () {
        this.getPatients();
    }
}
</script>

<style lang="scss" scoped>
.card-wrap {
    max-width: 800px;
}
</style>
