<template>
    <section class="errors mt-4">
        <div class="container">
            <template v-for="error in errors">
                <div class="alert alert-danger" role="alert">
                    <span class="error-message">
                        {{ error }}
                    </span>
                    <button type="button" class="btn-close" @click="clearErrors" />
                </div>
            </template>
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        errors () {
            return this.$store.getters['store/errors'];
        },
    },
    methods: {
        clearErrors: function () {
            this.$store.dispatch('store/clearErrors');
        }
    }
}
</script>

<style lang="scss" scoped>
section.errors {
    margin: 0 auto;
    .alert {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .btn {
        padding: 0;
    }
}
</style>

