<template>
  <section class="patient-dashboard mt-4" v-if="patient">
    <div class="container">
      <div class="section-title border-bottom">
        <h3>{{ pageTitle }}</h3>
        <template v-if="hasSigner">
          <h6>Patient: {{ patient.firstname }} {{ patient.lastname }}</h6>
        </template>
      </div>
      <div class="content-wrap">
        <nav class="nav navbar-nav nav-dashboard-side border-end">
          <router-link to="/dashboard/in-progress-admissions" class="nav-link">
            <i class="far fa-tasks"></i>
            In Progress Admissions
          </router-link>
          <router-link to="/dashboard/completed-admissions" class="nav-link">
            <i class="far fa-check"></i>
            Completed Admissions
          </router-link>
          <router-link to="/dashboard/uploads" class="nav-link">
            <i class="far fa-upload"></i>
            Uploads
          </router-link>
          <template v-if="hasSigner">
            <router-link to="/dashboard/other-patients" class="nav-link">
              <i class="far fa-users"></i>
              Other Patients
            </router-link>
          </template>
        </nav>

        <main>
          <router-view></router-view>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    computed: {
        patient () {
            return this.$store.getters['store/patient'];
        },
        hasPatient () {
            return this.$store.getters['store/hasPatient'];
        },
        signer () {
            return this.$store.getters['store/signer'];
        },
        hasSigner () {
            return this.$store.getters['store/hasSigner'];
        },
        pageTitle () {
            if (this.hasSigner) {
                return this.signer.firstname + '\'s Dashboard';
            }
            if (this.hasPatient) {
                return this.patient.firstname + '\'s Dashboard';
            }
            return 'Dashboard';
        }
    },
    mounted () {
        if (!this.$store.getters['store/isAuthenticated']) {
            this.$router.push('/');
        }
    }
}
</script>

<style lang="scss">
section.patient-dashboard {

    .section-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        margin-bottom: 20px;

        h3 {
            margin-bottom: 0;
        }
        h5 {
            margin-bottom: 0;
        }
    }
    h5 {
        margin-bottom: 16px;
        &:not(:first-of-type) {
            margin-top: 32px;
        }
    }
    .card {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }
    .content-wrap {
        display: flex;
        flex-direction: row;
    }
    nav {
        max-width: 300px;
        padding-right: 20px;
        margin-right: 20px;
        width: 100%;
        a {
            padding: 8px;
            text-align: center;
            font-size: 14px;
            border-radius: 8px;
            background-color: white;
            border: 1px solid;
            border-color: var(--bs-gray-100);
            &:not(:last-of-type) {
                margin-bottom: 8px;
            }
            &:hover {
                background-color: var(--bs-gray-200);
            }
            &.router-link-exact-active {
                background-color: var(--bs-primary);
                color: white;
            }
            svg {
                margin-right: 4px;
            }
        }
    }
    main {
        width: 100%;

        h5 {
            margin-bottom: 20px;
        }
        .card {
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }

    .no-admissions {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0;
    }

    @media screen and (max-width: 768px) {
        .content-wrap {
            flex-direction: column;
        }
        nav {
            max-width: unset;
            border: 0;
            margin-right: 0;
            padding-right: 0;
            margin-bottom: 40px;
        }
    }
}
</style>

