<template>
  <header>
    <nav class="navbar navbar-expand-lg bg-white py-3 shadow-sm" id="main-nav">
      <div class="container">
        <router-link to="/" class="navbar-brand">
            <img :src="appLogo" :alt="this.config.name" />
        </router-link>
        <button class="navbar-toggler font-weight-bold rounded" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fas fa-bars"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <template v-if="isAuthenticated">
            <ul class="navbar-nav ms-auto">
              <template v-if="importantDocuments">
                <li class="nav-item mx-0 mx-lg-1">
                  <li class="nav-item mx-0 mx-lg-1">
                    <router-link to="/dashboard" class="nav-link py-3 px-0 px-lg-3 link-secondary fw-bolder">
                      Dashboard
                    </router-link>
                  </li>
                </li>
                  <li class="nav-item mx-0 mx-lg-1 dropdown">
                    <a class="nav-link py-3 px-0 px-lg-3 dropdown-toggle link-secondary fw-bolder" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Important Documents
                    </a>
                    <ul class="dropdown-menu">
                      <template v-for="document in importantDocuments">
                        <li>
                          <a class="dropdown-item" :href="document.url" target="_blank">
                            {{ document.name }}
                          </a>
                        </li>
                      </template>
                      <li v-if="facilityDocuments.length > 0"><hr class="dropdown-divider"></li>
                      <li v-if="facilityDocuments.length > 0" class="dropdown-item">
                        <strong><small>{{ currentFacility.name }} Documents</small></strong>
                      </li>
                      <template v-for="document in facilityDocuments">
                        <li>
                          <a class="dropdown-item" :href="document.url" target="_blank">
                            {{ document.name }}
                          </a>
                        </li>
                      </template>
                    </ul>
                  </li>
              </template>
              <li class="nav-item mx-0 mx-lg-1">
                <li class="nav-item mx-0 mx-lg-1">
                  <a href="#" class="nav-link py-3 px-0 px-lg-3 link-secondary fw-bolder" @click="logout">
                    Logout
                  </a>
                </li>
              </li>
            </ul>
          </template>
          <template v-else>
            <ul class="navbar-nav ms-auto">
              <li class="nav-item mx-0 mx-lg-1">
                <router-link to="/patient/login" class="nav-link py-3 px-0 px-lg-3 link-secondary fw-semibold">
                  {{ admitteeNomenclature }}
                </router-link>
              </li>
              <li class="nav-item mx-0 mx-lg-1">
                <RouterLink to="/signer/login" class="nav-link py-3 px-0 px-lg-3 link-secondary fw-semibold" activeClass="link-primary">
                  Responsible Party
                </RouterLink>
              </li>
              <li class="nav-item mx-0 mx-lg-1">
                <router-link to="/admin/login" class="nav-link py-3 px-0 px-lg-3 link-secondary fw-semibold">
                  Facility Rep
                </router-link>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import config from "../../mixins/app/config";
import endpoints from "../../mixins/app/endpoints";
import Cookies from "js-cookie";
import 'bootstrap';
import { useAuthenticatedClient } from "@/mixins/app/useAuthenticatedClient";

export default {
    mixins: [
        config,
        endpoints
    ],
    computed: {
        currentFacility () {
            return window.currentFacility
        },
        facilityDocuments () {
            return window.facilityDocuments
        },
        importantDocuments () {
            return window.importantDocuments;
        },
        isAuthenticated () {
            return this.$store.getters['store/isAuthenticated'];
        },
        admitteeNomenclature () {
            return window.admitteeNomenclatureTitle
        }
    },
    methods: {
        logout () {
            this.$store.dispatch('store/clearErrors');
            const { client } = useAuthenticatedClient(this.$store);
            client.post(
                '/api/v1/logout',
                {
                    _token: window.csrf_token
                }
            ).then(response => {
                Cookies.remove('access_token');
                Cookies.remove('patient');
                Cookies.remove('signer');

                window.location = '/';
            }).catch(error => {``
                // Nothing to do
            });
        }
    },
    watch: {
        isAuthenticated (value) {
        }
    }
}
</script>
