import { createRouter, createWebHistory } from 'vue-router'
import store from '../vuex/app'

import AdminLogin from "../pages/app/AdminLogin.vue";
import ForgotPassword from "../pages/app/ForgotPassword.vue";
import Home from "../pages/app/Home.vue";
import PatientLogin from "../pages/app/PatientLogin.vue";
import Dashboard from "../pages/app/Dashboard.vue";
import Sign from "../pages/app/Sign.vue";
import ResponsiblePartyLogin from "../pages/app/ResponsiblePartyLogin.vue";
import InProgressAdmissions from "../pages/app/components/InProgressAdmissions.vue";
import CompletedAdmissions from "../pages/app/components/CompletedAdmissions.vue";
import Uploads from "../pages/app/components/Uploads.vue";
import OtherPatients from "../pages/app/components/OtherPatients.vue";
import SignerFollowup from "../pages/app/SignerFollowup.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            showNav: false
        }
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword,
        meta: {
            showNav: true
        }
    },
    {
        path: '/admin/login',
        name: 'AdminLogin',
        component: AdminLogin,
        meta: {
            showNav: true
        },
    },
    {
        path: '/patient/login',
        name: 'PatientLogin',
        component: PatientLogin,
        meta: {
            showNav: true
        },
    },
    {
      path: '/signer/followup/:signatureId',
      name: 'SignerFollowup',
      component: SignerFollowup,
      meta: {
        showNav: true
      }
    },
    {
        path: '/signer/login',
        name: 'ResponsiblePartyLogin',
        component: ResponsiblePartyLogin,
        meta: {
            showNav: true
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            showNav: true
        },
        children: [
            {
                path: '',
                name: 'Default',
                redirect: '/dashboard/in-progress-admissions'
            },
            {
                path: 'in-progress-admissions',
                name: 'InProgressAdmissions',
                component: InProgressAdmissions
            },
            {
                path: 'completed-admissions',
                name: 'CompletedAdmissions',
                component: CompletedAdmissions
            },
            {
                path: 'uploads',
                name: 'Uploads',
                component: Uploads
            },
            {
                path: 'other-patients',
                name: 'OtherPatients',
                component: OtherPatients
            }
        ]
    },
    {
        path: '/sign/:id/:signatureId',
        name: 'Sign',
        component: Sign,
        meta: {
            showNav: false
        }
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router
