export default {
    computed: {
        config () {
            return window.config;
        },
        appLogo () {
            if (!_.isEmpty(this.config.logo)) {
                return this.config.logo;
            }
            return '/images/logo.png';
        }
    }
}
