<template>
    <div class="main-content-wrap">
        <h5>Uploads</h5>
        <div class="card-wrap">
            <PatientUploads></PatientUploads>
        </div>
    </div>
</template>

<script>
import PatientUploads from "../../../components/app/PatientUploads.vue";

export default {
    components: {
        PatientUploads
    },
}
</script>

<style lang="scss" scoped>
.card-wrap {
    max-width: 800px;
}
</style>
