import { computed } from "vue"

export const useEndpoints = () => {

  const endpoints = computed<Record<string, string>>(() => {
    if (!window.endpoints) {
      throw new Error("Endpoints not found in window object.")
    }
    return window.endpoints;
  })

  return {
    endpoints
  }
}