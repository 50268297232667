<template>
    <section class="section-narrow">
        <div class="container">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Forgot Password</h5>
                    <hr>

                    <div class="form-floating">
                      <input type="email" class="form-control" id="adminLoginEmail" v-model="email" placeholder="Email">
                      <label for="adminLoginEmail" class="form-label">Email</label>
                    </div>


                    <hr>

                    <button type="submit" class="btn btn-primary float-end" @click="forgotPassword">
                        Send password reset
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import endpoints from "../../mixins/app/endpoints";
import axios from '@/axios'

export default {
    mixins: [endpoints],
    data () {
        return {
            email: null,
        }
    },
    methods: {
        forgotPassword: function () {
            this.$store.dispatch('store/clearErrors');
            axios.post(this.endpoints.forgotPassword, { email: this.email }).then(response => {
                window.location = '/';
            }).catch(error => {
                this.$store.dispatch('store/setErrors', [ error.response.data.message ]);
            });
        }
    }
}
</script>

<style lang="scss" scoped></style>

