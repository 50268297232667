<template>
  <section class="content">
    <div class="container">
      <div class="card mx-auto pt-3" style="width: 400px">
        <div class="card-body">
          <img :src="appLogo" :alt="this.config.name" class="mx-auto d-block my-4 mt-2" style="height: 75px;"/>
          <p class="text-center pb-4">Please select your role to begin.</p>
          <div class="d-grid">
            <router-link to="/patient/login" class="btn btn-lg btn-primary mb-2">{{ admitteeNomenclature }}</router-link>
            <router-link to="/signer/login" class="btn btn-lg btn-primary">Responsible Party</router-link>
          </div>
        </div>
        <div class="card-footer d-grid">
          <a href="/admin" class="btn btn-secondary">Facility Representative</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import config from "../../mixins/app/config";
import endpoints from "../../mixins/app/endpoints";

export default {
    mixins: [
        config,
        endpoints
    ],
    mounted () {
        if (this.$store.getters['store/isAuthenticated']) {
            this.$router.push('/dashboard');
        }
    },
    computed: {
        admitteeNomenclature () {
            return window.admitteeNomenclatureTitle
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    margin-top: 80px;
}
</style>

