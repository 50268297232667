import Cookies from "js-cookie"
import axios from "../../axios"
import { Store } from "vuex"
import { AxiosError } from "axios"

export const useAuthenticatedClient = (store: Store<any>) => {

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('access_token')

  axios.interceptors.request.use(config => {
    store.dispatch('store/clearErrors')
    return config
  })

  axios.interceptors.response.use(
    (config) => {
      return config
    },
    (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401 && error.response.data.message === 'Unauthenticated.') {
          Cookies.remove('access_token')
          Cookies.remove('patient')
          Cookies.remove('signer')
          window.location.href = '/'
        } else if (error.response?.data.message) {
          store.dispatch('store/setErrors', [ error.response.data.message ])
        }
      }
      return Promise.reject(error)
    }
  )

  return {
    client: axios
  }
}

