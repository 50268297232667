<template>
    <div id="page-wrap" :class="getClassName">
        <template v-if="showNav">
            <NavBar></NavBar>
        </template>

        <template v-if="errors">
            <Errors></Errors>
        </template>

        <router-view></router-view>
    </div>
</template>

<script>
import Cookies from "js-cookie";

import Errors from '../components/app/Errors.vue';
import NavBar from '../components/app/NavBar.vue';
export default {
    name: 'app',
    components: {
        Errors,
        NavBar
    },
    computed: {
        errors () {
            return this.$store.getters['store/errors'];
        },
        showNav () {
            return this.$route.meta.showNav;
        },
        getClassName () {
            return 'page-' + _.kebabCase(this.$route.name);
        }
    },
    mounted () {
        this.$store.dispatch('store/clearErrors');

        let patient = Cookies.get('patient');
        if (patient !== undefined) {
            this.$store.dispatch('store/setPatient', JSON.parse(patient));
        }

        let signer = Cookies.get('signer');
        if (signer !== undefined) {
            this.$store.dispatch('store/setSigner', JSON.parse(signer));
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
