<template>
    <div class="card">
        <div class="card-body">
            <template v-if="error">
                <div class="alert alert-danger">{{ error }}</div>
            </template>
            <p>
                Please do not upload admission documents here.<br/>
                Only documents such as ID, POA, or Insurance cards.
            </p>
            <hr>
            <div class="row">
                <div class="col-md-8">
                    <input type="file" class="form-control" @change="handleFileUpload($event)">
                </div>
                <div class="col-md-4 text-end">
                    <button name="button" class="btn btn-block btn-success" @click="uploadFile">
                        <i class="far fa-upload"></i>
                        Upload File
                    </button>
                </div>
            </div>

            <template v-if="uploads.length !== 0">
                <hr>
                <table class="table table-borderless">
                    <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Date</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="upload in uploads">
                        <tr>
                            <td>
                                <span>{{ upload.name }}</span>
                            </td>
                            <td>
                                <span>{{ upload.created_at }}</span>
                            </td>
                            <td class="text-end">
                                <button class="btn btn-sm btn-outline-primary" @click="download(upload)">
                                    <i class="far fa-download"></i>
                                    Download
                                </button>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </template>
        </div>
    </div>
</template>

<script>
import { useAuthenticatedClient } from '@/mixins/app/useAuthenticatedClient';
import FileSaver from 'file-saver';

export default {
    name: 'patient-uploads',
    data () {
        return {
            form: {
                file: null
            },
            error: null,
            uploads: []
        }
    },
    computed: {
        patient () {
            return this.$store.getters['store/patient'];
        },
    },
    mounted () {
        this.getUploads();
    },
    methods: {
        getUploads: function () {
            let self = this;
            const { client } = useAuthenticatedClient(this.$store);
            client.get('/api/v1/patients/' + this.patient.id + '/uploads').then(response => {
                self.uploads = response.data.uploads;
            }).catch(error => {
                // Do Nothing
            });
        },
        download (upload) {
            this.error = null;
            client.get(
                '/api/v1/patients/' + this.patient.id + '/uploads/download/' + upload.id,
                { responseType: 'blob' }
            ).then(response => {
                FileSaver.saveAs(response.data, upload.name);
            }).catch(error => {
                this.error = 'Unable to download file, please try again later.';
            });
        },
        handleFileUpload (event) {
            this.form.file = event.target.files[0];
        },
        uploadFile () {
            this.error = null;
            if (this.form.file === null) {
                this.error = 'Please select a file.';
                return;
            }

            let formData = new FormData();
            formData.append('file', this.form.file);
            formData.append('patientId', this.patient.id);

            client.post( '/api/v1/patients/' + this.patient.id + '/uploads/store', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then((response) => {
                window.location.replace('/dashboard/uploads');
            }).catch((error) => {
                // Do Nothing
            });
        }
    }
}
</script>

<style lang="scss" scoped>
table {
    margin-top: 16px;
}
table.table-borderless th {
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
table {
    span {
        white-space: nowrap;
    }
}
</style>
