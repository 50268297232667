<template>
    <div class="card card-admission">
        <div class="card-body table-responsive dashboard-admission-tables">
            <table class="table mb-4">
                <thead>
                <tr>
                    <th scope="col" class="">Facility</th>
                    <th scope="col" class="text-center">Admission Date</th>
                    <th scope="col" class="text-center">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <span>{{ admission.facility.name }}</span>
                    </td>
                    <td class="text-center">
                        <template v-if="admission.admission_date">
                            <span>{{ admission.admission_date }}</span>
                        </template>
                        <template v-else><span>Not Set</span></template>
                    </td>
                    <td class="text-center">
                        <span>{{ admission.status }}</span>
                    </td>
                </tr>
                </tbody>
            </table>

            <table class="table mb-0">
                <thead>
                <tr>
                    <th scope="col">Document</th>
                    <th scope="col" class="text-center">Document Status</th>
                    <th scope="col" class="text-center">Signature Status</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="document in admission.documents">
                    <DocumentDetails :document="document"></DocumentDetails>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import DocumentDetails from "./DocumentDetails.vue";

export default {
    props: ['admission'],
    components: {
        DocumentDetails
    }
}
</script>

<style lang="scss">
.dashboard-admission-tables {
    th {
        color: var(--bs-gray-700);
    }
    tbody {
        tr {
            &:last-of-type {
                td {
                    border-bottom: 0;
                }
            }
        }
    }
}
</style>
