import axios, { AxiosError } from 'axios'
import Cookies from 'js-cookie'

// Create an axios instance
const instance = axios.create({
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
      'Authorization': 'Bearer ' + Cookies.get('access_token')
    }
  },
  withCredentials: true
})

// Add a response interceptor to redirect
// to login page if the user is not authenticated
instance.interceptors.response.use(config => {
  return config
}, error => {
  if (! (error instanceof AxiosError)) {
    return Promise.reject(error)
  }

  if (! error.response) {
    return Promise.reject(error)
  }

  if (error.response.status === 401) {
    window.location.href = '/'
  }
  
  if (error.response.status === 419 && error.response.data.message === 'CSRF token mismatch.') {
    window.location.href = '/'
  }

  return Promise.reject(error)
})

export default instance