<template>
    <div class="loading">
        <i class="fa-regular fa-loader fa-spin fa-4x"></i>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
.loading {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bs-gray-500);
}
</style>

