<template>
    <div class="main-content-wrap">
        <h5>Completed Admissions</h5>

        <template v-if="isLoading">
            <Loading></Loading>
        </template>

        <template v-if="hasAdmissions">
            <template v-for="admission in admissions">
                <AdmissionDetails :admission="admission"></AdmissionDetails>
            </template>
        </template>

        <template v-if="!isLoading && !hasAdmissions">
            <div class="no-admissions">No Admissions Found</div>
        </template>
    </div>
</template>

<script>
import AdmissionDetails from "../../../components/app/AdmissionDetails.vue";
import Loading from "../../../components/app/Loading.vue";
import { useAuthenticatedClient } from "@/mixins/app/useAuthenticatedClient";

export default {
    components: {
        AdmissionDetails,
        Loading
    },
    computed: {
        patient () {
            return this.$store.getters['store/patient'];
        },
        hasAdmissions () {
            return !_.isEmpty(this.admissions);
        }
    },
    data () {
        return {
            admissions: null,
            isLoading: false,
            client: null
        };
    },
    mounted () {
        this.getAdmissions();
    },
    methods: {
        getAdmissions: function () {
          let self = this;
          self.isLoading = true;
          const { client } = useAuthenticatedClient(this.$store);

          client.get('/api/v1/patients/' + this.patient.id + '/admissions/completed').then(response => {
              self.admissions = response.data.admissions;
          }).catch(error => {
              // Do Nothing
          }).finally(() => {
              self.isLoading = false;
          });
        }
    }
}
</script>
